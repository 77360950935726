@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");


/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  color: #212529;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue","Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


a {
  color: #6D6CF6;
  text-decoration: none;
}

a:hover {
  color: #7c7cfc;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, .font-primary {
  font-family: "Raleway", sans-serif;
}


::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-track {
  background: rgb(251, 251, 251) !important;
}

::-webkit-scrollbar-thumb {
  background: #efefef !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(176, 178, 180) !important;
}
