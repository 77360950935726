
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #204e1e;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top svg {
    font-size: 24px;
    color: #fff;
    line-height: 0;
  }
  
  .back-to-top:hover {
    background: #72976f;
    color: #fff;
  }
  
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    height: 72px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #header .logo h1 {
    font-size: 30px;
    margin: 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
  }
  
  #header .logo h1 a, #header .logo h1 a:hover {
    color: #517a4e;
    text-decoration: none;
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    max-height: 60px;
  }
  
  #main {
    margin-top: 72px;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar li {
    position: relative;
  }
  
  .navbar a, .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px; 
    font-size: 15px;
    color: #000;
    white-space: nowrap;
    transition: 0.3s;
  }
  
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #204e1e;
  }
  
  .navbar .getstarted {
    background: #204e1e;
    color: #fff;
    padding: 10px 25px;
    margin-left: 30px;
    border-radius: 50px;
  }
  
  .navbar .getstarted:hover {
    color: #fff;
    background: #72976f;
  }
  
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
  }
  
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #204e1e;
  }
  
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #204e1e;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
   /* padding: 0 20px; /* Add horizontal padding */
    width: 50px; 
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
    .navbar ul {
      display: none;
    }
  }
  
  .navbarMobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(78, 64, 57, 0.9);
    transition: 0.3s;
  }
  
  .navbarMobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbarMobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbarMobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #517a4e;
  }
  
  .navbarMobile a:hover, .navbarMobile .active, .navbarMobile li:hover > a {
    color: #204e1e;
  }
  
  .navbarMobile .getstarted {
    margin: 15px;
  }
  
  .navbarMobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbarMobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbarMobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbarMobile .dropdown ul a i {
    font-size: 12px;
  }
  
  .navbarMobile .dropdown ul a:hover, .navbarMobile .dropdown ul .active:hover, .navbarMobile .dropdown ul li:hover > a {
    color: #204e1e;
  }
  
  .navbarMobile .dropdown > .dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    width: 100%;
    height: 70vh;
    background: #fef8f5;
    /* border-bottom: 2px solid #fcebe3; */
    /* margin: 72px 0 -72px 0; */
  }
  
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #000;
  }
  
  #hero h2 {
    color: #212529;
    margin: 15px 0 0 0;
    font-size: 24px;
  }
  
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 3px;
    transition: 0.5s;
    margin-top: 30px;
    color: #fff;
    background: #204e1e;
  }
  
  #hero .btn-get-started:hover {
    background: #72976f;
  }
  
  #hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  
  @media (max-width: 991px) {
    #hero {
      height: calc(100vh - 72px);
    }
    #hero .animated {
      -webkit-animation: none;
      animation: none;
    }
    #hero .hero-img {
      text-align: center;
    }
    #hero .hero-img img {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
    }
    #hero .hero-img img {
      width: 70%;
    }
  }
  
  @media (max-width: 575px) {
    #hero .hero-img img {
      width: 80%;
    }
  }
  
  @media (max-height: 600px) {
    #hero {
      height: calc(120vh);
    }
  }
  
  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #fef8f5;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
    color: #000;
  }
  
  .section-title h2 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 0;
    line-height: 1px;
    margin-bottom: 15px;
    color: #204e1e;
  }
  
  .section-title p {
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
    font-size: 32px;
    font-weight: 700;
    color: #000;
  }
  
  .section-title p::after {
    content: '';
    position: absolute;
    display: block;
    width: 60px;
    height: 2px;
    background: #204e1e;
    bottom: 0;
    left: calc(50% - 30px);
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 15px 0;
    background-color: #fef5f1;
    min-height: 40px;
  }
  
  .breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
  }
  
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about h3 {
    font-weight: 700;
    font-size: 34px;
    color: #000;
  }
  
  .about h4 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
    color: #000;
  }
  
  .about i {
    font-size: 48px;
    margin-top: 15px;
    color: #72976f;
  }
  
  .about p {
    font-size: 15px;
    color: #3b4248;
  }
  
  @media (max-width: 991px) {
    .about .about-img img {
      max-width: 70%;
    }
  }
  
  @media (max-width: 767px) {
    .about .about-img img {
      max-width: 90%;
    }
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .service-single{
    cursor: pointer;
  }
  .services .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    margin: 0  0 40px 0;
    background: #e8ffe3;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    text-align: center;
    border-bottom: 3px solid #fff;
  }
  
  .services .icon-box:hover {
    transform: translateY(-5px);
    border-color: #72976f;
  }

  
  .services .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #111;
  }
  
  .services .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .service-image {
    width: 100%; 
    height: 200px; 
    object-fit: contain;   
    background-color: #f5f5f5; 
}
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 0 35px 0;
    list-style: none;
    text-align: center;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    margin: 0 15px 15px 0;
    display: inline-block;
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #212529;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
    color: #204e1e;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    z-index: 1;
  }
  
  .portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(122, 105, 96, 0.6);
    position: absolute;
    left: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.3s;
    z-index: 2;
  }
  
  .portfolio .portfolio-wrap img {
    transition: 0.3s;
    position: relative;
    z-index: 1;
  }
  
  .portfolio .portfolio-wrap .portfolio-links {
    opacity: 0;
    left: 0;
    right: 0;
    top: calc(50% - 32px);
    text-align: center;
    z-index: 3;
    position: absolute;
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a {
    color: #204e1e;
    margin: 0 4px;
    line-height: 0;
    background-color: #fff;
    padding-top: 6px;
    padding-right: 1px;
    border-radius: 50px;
    text-align: center;
    width: 32px;
    height: 32px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a i {
    line-height: 0;
    font-size: 20px;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a:hover {
    background: #204e1e;
    color: #fff;
  }
  
  .portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
  
  .portfolio .portfolio-wrap .portfolio-info p {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
  }
  
  .portfolio .portfolio-wrap:hover::before {
    left: 0;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-links {
    opacity: 1;
    top: calc(50% - 16px);
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
    padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #204e1e;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #204e1e;
  }
  
  .portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(122, 105, 96, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li + li {
    margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
    padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # F.A.Q
  --------------------------------------------------------------*/
  .faq {
    padding: 60px 0;
  }
  
  .faq .faq-list {
    padding: 0;
    list-style: none;
  }
  
  .faq .faq-list li {
    border-bottom: 1px solid #eae7e5;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  .faq .faq-list .question {
    display: block;
    position: relative;
    font-family: #204e1e;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-weight: 600;
    padding-left: 25px;
    cursor: pointer;
    color: #c54811;
    transition: 0.3s;
  }
  
  .faq .faq-list i {
    font-size: 16px;
    position: absolute;
    left: 0;
    top: -2px;
  }
  
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 25px;
  }
  
  .faq .faq-list .icon-show {
    display: none;
  }
  
  .faq .faq-list .collapsed {
    color: #343a40;
  }
  
  .faq .faq-list .collapsed:hover {
    color: #204e1e;
  }
  
  .faq .faq-list .collapsed .icon-show {
    display: inline-block;
    transition: 0.6s;
  }
  
  .faq .faq-list .collapsed .icon-close {
    display: none;
    transition: 0.6s;
  }
  
  /*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
  .team {
    background: #fff;
    padding: 60px 0;
  }
  
  .team .member {
    text-align: center;
    margin-bottom: 20px;
    background: #343a40;
    position: relative;
    overflow: hidden;
  }
  .team .member img {
    width: 100%; 
    height: 250px; 
    object-fit: contain; 
    background-color: #e8ffe3; 
  
  }


  .team .member .member-info {
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s;
  }
  
  .team .member .member-info-content {
    position: absolute;
    left: 50px;
    right: 0;
    bottom: 0;
    transition: bottom 0.4s;
  }
  
  .team .member .member-info-content h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: #fff;
  }
  
  .team .member .member-info-content span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff;
  }
  
  .team .member .social {
    position: absolute;
    left: -50px;
    top: 0;
    bottom: 0;
    width: 50px;
    transition: left ease-in-out 0.3s;
    background: rgba(78, 64, 57, 0.6);
    text-align: center;
  }
  
  .team .member .social a {
    transition: color 0.3s;
    display: block;
    color: #fff;
    margin-top: 15px;
  }
  
  .team .member .social a:hover {
    color: #204e1e;
  }
  
  .team .member .social i {
    font-size: 18px;
    margin: 0 2px;
  }
  
  .team .member:hover .member-info {
    background: linear-gradient(0deg, rgba(78, 64, 57, 0.95) 0%, rgba(78, 64, 57, 0.95) 15%, rgba(255, 255, 255, 0) 100%);
    opacity: 1;
    transition: 0.4s;
  }
  
  .team .member:hover .member-info-content {
    bottom: 30px;
    transition: bottom 0.4s;
  }
  
  .team .member:hover .social {
    left: 0;
    transition: left ease-in-out 0.3s;
  }
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  .clients .clients-slider {
    align-items: center;
    justify-content: center;
  }
  .clients .clients-slider .swiper-slide img {
    opacity: 0.5;
    transition: 0.3s;
  }
  
  .clients .clients-slider .swiper-slide img:hover {
    opacity: 1;
  }
  
  .clients .clients-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .clients-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #204e1e;
  }
  
  .clients .clients-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #204e1e;
  }
  
  /*--------------------------------------------------------------
  # Contact Us
  --------------------------------------------------------------*/
  .contact .info {
    border-top: 3px solid #204e1e;
    border-bottom: 3px solid #204e1e;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  
  .contact .info svg {
    font-size: 20px;
    color: #204e1e;
    float: left;
    width: 44px;
    height: 44px;
    background: #ddddfe9c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #517a4e;
  }
  
  .contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #ab9d95;
  }
  
  .contact .info .email p {
    padding-top: 5px;
  }
  
  .contact .info .social-links {
    padding-left: 60px;
  }
  
  .contact .info .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #333;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 10px;
  }
  
  .contact .info .social-links a:hover {
    background: #204e1e;
    color: #fff;
  }
  
  .contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #4b4bbf;
    color: #fff;
  }
  
  .contact .php-email-form {
    width: 100%;
    border-top: 3px solid #204e1e;
    border-bottom: 3px solid #204e1e;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input {
    height: 44px;
  }
  
  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  
  .contact .php-email-form button[type="submit"] {
    background: #204e1e;
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
    background: #ef7f4d;
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    background: #fff;
    padding: 0 0 30px 0;
    color: #212529;
    font-size: 14px;
    background: #fef8f5;
  }
  
  #footer .footer-newsletter {
    padding: 50px 0;
    background: #fef8f5;
    text-align: center;
    font-size: 15px;
  }
  
  #footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    color: #000;
  }
  
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  #footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px 4px;
    width: calc(100% - 100px);
  }
  
  #footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #204e1e;
    color: #fff;
    transition: 0.3s;
    border-radius: 4px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  #footer .footer-newsletter form input[type="submit"]:hover {
    background: #72976f;
  }
  
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
  }
  
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #5c5c5c;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #212529;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #72976f;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #5c5c5c;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #204e1e;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #204e1e;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #72976f;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .copyright {
    text-align: center;
    float: left;
  }
  
  #footer .credits {
    float: right;
    text-align: center;
    font-size: 13px;
    color: #212529;
  }
  
  #footer .credits a {
    color: #204e1e;
  }
  
  @media (max-width: 575px) {
    #footer .copyright, #footer .credits {
      float: none;
      -moz-text-align-last: center;
      text-align-last: center;
      padding: 3px 0;
    }
  }
  















  .logout-btn {
    font-weight: 500;
  }
  
  /* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
  
  .wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    perspective: 1500px;
    background: #f4f7fc;
  }
  
  .wrapper .navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper .navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
  }
  
  #sidebar {
    min-width: 300px;
    max-width: 300px;
    height: 100vh;
    position: sticky;
    top: 0;
    background: white;
    color: #878787;
    transition: all 0.6s cubic-bezier(0.945, 0.02, 0.27, 0.665);
    transform-origin: bottom left;
  }
  
  #sidebar.active {
    margin-left: -300px;
    transform: rotateY(100deg);
  }
  
  #sidebar .sidebar-header {
    padding-left: 3rem !important;
    padding: 20px;
    background: white;
  }
  
  #sidebar .sidebar-header h2 {
    color: black;
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 0;
    margin-top: 0.2rem;
  }
  
  #sidebar ul.components {
    height: 78vh;
    padding: 20px 0;
    padding-left: 2rem;
  }
  
  #sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  #sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    transition: 0s;
  }
  #sidebar ul li a:hover {
    margin-right: 10px;
    color: black;
    background: #f4f7fc;
    transition: 0s;
  }
  
  #sidebar ul li:active,
  #sidebar ul li a:active {
    color: black !important;
    background: #f4f7fc;
    transition: 0s;
  }
  
  #sidebar ul li a span {
    margin-left: 0.3rem;
  }
  
  .link-active {
    color: black !important;
    font-weight: 500;
  }
  
  a,
  a:hover,
  a:focus {
    color: inherit;
    text-decoration: none;
  }
  
  #sidebar ul li.active > a,
  a[aria-expanded="true"] {
    color: black;
    background: #f4f7fc;
  }
  
  a[data-toggle="collapse"] {
    position: relative;
  }
  
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  
  #sidebar ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
  }
  
  #sidebar ul.CTAs {
    padding: 20px;
  }

  #sidebar a.back-home:hover,
  a.back-home:focus,
  a.back-home:active {
    color: white ;
    margin-right: 0 !important;
  }
  
  #sidebar ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    background-color: #204e1e !important;
    margin-bottom: 5px;
  }
  #sidebar ul.CTAs a:hover{
    color: black !important;
    /* background: #6d7fcc !important; */
  }
  

  /* ---------------------------------------------------
        CONTENT STYLE
    ----------------------------------------------------- */
  #content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
  }
  
  #sidebarCollapse {
    width: 40px;
    height: 40px;
    /* background: #a22727; */
    cursor: pointer;
  }
  
  #sidebarCollapse span {
    width: 80%;
    height: 2px;
    margin: 0 auto;
    display: block;
    background: #204e1e;
    transition: all 0.8s cubic-bezier(0.81, -0.33, 0.345, 1.375);
    transition-delay: 0.2s;
  }
  
  #sidebarCollapse span:first-of-type {
    transform: rotate(45deg) translate(2px, 2px);
  }
  #sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
  }
  #sidebarCollapse span:last-of-type {
    transform: rotate(-45deg) translate(1px, -1px);
  }
  
  #sidebarCollapse.active span {
    transform: none;
    opacity: 1;
    margin: 5px auto;
  }
  
  /* ---------------------------------------------------
        MEDIAQUERIES
    ----------------------------------------------------- */
  @media (max-width: 768px) {
    #sidebar {
      min-width: 250px;
      max-width: 250px;
      margin-left: -250px;
      transform: rotateY(90deg);
    }
    #sidebar.active {
      margin-left: 0;
      transform: none;
    }
    #sidebarCollapse span:first-of-type,
    #sidebarCollapse span:nth-of-type(2),
    #sidebarCollapse span:last-of-type {
      transform: none;
      opacity: 1;
      margin: 5px auto;
    }
    #sidebarCollapse.active span {
      margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
      transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
      opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
      transform: rotate(-45deg) translate(1px, -1px);
    }
    #sidebar ul.components {
      padding-left: 1rem;
    }
  }
  