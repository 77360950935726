.modal-content {
  border-radius: 20px;
  padding: 1rem;
}

.modal-header {
  border-bottom: none;
  padding: 0.5rem 1rem;
}

.modal-body {
  padding: 1rem;
}

.modal-body input,
button {
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem;
  font-size: 1rem;
}

.modal-body .quote-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.modal-body .form {
  width: 100%;
  padding: 1rem;
}

.modal-body label {
  width: 100%;
  margin-top: 1rem;
}

.modal-body select,
textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-body button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  background-color: #fd7e15;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.modal-body button:hover {
  background-color: #e9710e;
}

.modal-body textarea {
  resize: none;
  min-height: 100px;
}

@media (max-width: 768px) {
  .modal-content {
    padding: 1rem;
  }

  .modal-header {
    padding: 0.5rem;
  }

  .modal-body .form {
    padding: 1rem;
  }

  .modal-body label {
    margin-top: 1rem;
  }

  .modal-body input,
  .modal-body select,
  .modal-body textarea {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .modal-body button {
    font-size: 1rem;
    padding: 0.75rem;
  }

  .modal-body h2 {
    font-size: 1.5rem;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .modal-body h2 {
    font-size: 1.25rem;
  }

  .modal-body {
    padding: 0.5rem;
  }

  .modal-body input,
  .modal-body select,
  .modal-body textarea {
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .modal-body button {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}
.required {
  color: red;
  margin-left: 4px;
}
